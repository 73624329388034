import type { GetServerSideProps, NextPage } from 'next';
import cx from 'classnames';
import { useRouter } from 'next/router';
import Image from 'next/image';
import Link from 'next/link';
import { signIn, useSession } from 'next-auth/react';
import { useCallback, useState, useEffect, FormEvent } from 'react';
import { Button, LoadingSpinner, TextField } from 'components/base';

import podsLogo from 'public/images/pods-logo-2.png';
import Page from 'components/wrappers/Page';

const Login: NextPage<{ error: string; existingEmail: string }> = ({ error, existingEmail }) => {
  const [isLoading, setIsLoading] = useState(false);

  const user = useSession();
  const router = useRouter();

  const [email, setEmail] = useState<string>(existingEmail);
  const [password, setPassword] = useState('');

  const _signIn = useCallback(
    (e: FormEvent | MouseEvent) => {
      e.preventDefault();
      signIn('credentials', { email: email.toLowerCase(), password });
      setIsLoading(true);
    },
    [email, password]
  );

  useEffect(() => {
    if (user.status === 'authenticated') router.push('/');
  }, [user, router]);

  return (
    <Page
      className={cx('Login transition-opacity duration-[3s] delay-1000', {
        'opacity-0': user.status === 'authenticated'
      })}
      title="Login - PODS"
    >
      <div className="fixed top-0 left-0 right-0 bottom-0 bg-stone-900 flex flex-col items-center justify-center">
        <div className="Login__logo relative w-[188px] mb-12">
          <Image src={podsLogo} alt="PODS" />
        </div>
        {user.status === 'authenticated' ? (
          <div className="flex items-center">
            <span className="text-cyan-600 font-bold text-lg tracking-wide">Initializing</span>
            <LoadingSpinner className="text-cyan-600 ml-2" />
          </div>
        ) : (
          <div className="flex rounded-sm bg-white p-5 drop-shadow-sm flex-col items-center w-[360px]">
            <form className="flex w-full">
              <div className="flex flex-col w-full">
                <div className="mb-6 flex flex-col">
                  <TextField
                    placeholder="example@escapod.us"
                    label="Email"
                    ariaLabel="Email"
                    id="login_email"
                    type="text"
                    name="email"
                    value={email}
                    onChange={value => setEmail(value as string)}
                  />
                </div>
                <div className="mb-6 flex flex-col">
                  <TextField
                    placeholder="•••••••••••••••"
                    label="Password"
                    ariaLabel="Password"
                    id="login_password"
                    type="password"
                    name="password"
                    onChange={value => setPassword(value as string)}
                  />
                </div>
                {!!error && (
                  <div className="mb-4">
                    {error === 'no_user' && (
                      <span className="text-rose-500 text-sm">
                        We could not identify an account associated with these credentials.
                      </span>
                    )}
                    {error === 'invalid_login' && (
                      <span className="text-rose-500 text-sm">
                        Please provide a valid user and password combination.
                      </span>
                    )}
                  </div>
                )}
                <div className="mb-12">
                  <Link href="/login/password">
                    <a className="text-sm text-cyan-500">Forgot your password?</a>
                  </Link>
                </div>
                <Button variant="secondary" onClick={_signIn} type="submit" disabled={isLoading}>
                  {isLoading ? 'Signing In...' : 'Sign In'}
                </Button>
              </div>
            </form>
          </div>
        )}
      </div>
    </Page>
  );
};

export const getServerSideProps: GetServerSideProps = async context => {
  const { query } = context;
  const mode = query?.mode;
  const oobCode = query?.oobCode;
  const error = query?.error || '';
  const existingEmail = query?.email || '';

  if (mode === 'resetPassword' && oobCode) {
    return {
      redirect: { destination: `/login/password/reset?code=${oobCode}` },
      props: {}
    };
  }

  // TO-DO: Handle invalid oobCode

  return { props: { query, error, existingEmail } };
};

export default Login;
