import React, { FC, ReactNode } from 'react';
import cx from 'classnames';
import { useSearchState } from 'contexts/SearchContext';
import Head from 'next/head';

const Page: FC<{ children?: ReactNode; className?: string; title?: string }> = ({
  children,
  className = '',
  title = 'PODS'
}) => {
  const { searchIsActive } = useSearchState();
  return (
    <>
      <Head>
        <title>{title}</title>
      </Head>
      <div
        className={cx('Page animate-fade-in', className, {
          'overflow-hidden h-screen': searchIsActive
        })}
      >
        <div className="Page__inner pb-12">{children}</div>
      </div>
    </>
  );
};
export default Page;
